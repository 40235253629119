export const CREATE_HIGHLIGHT_REQUEST = 'CREATE_HIGHLIGHT_REQUEST';
export const CREATE_HIGHLIGHT_SUCCESS = 'CREATE_HIGHLIGHT_SUCCESS';
export const CREATE_HIGHLIGHT_FAIL = 'CREATE_HIGHLIGHT_FAIL';
export const CREATE_HIGHLIGHT_RESET = 'CREATE_HIGHLIGHT_RESET';

export const ALL_HIGHLIGHTS_REQUEST = 'ALL_HIGHLIGHTS_REQUEST';
export const ALL_HIGHLIGHTS_SUCCESS = 'ALL_HIGHLIGHTS_SUCCESS';
export const ALL_HIGHLIGHTS_FAIL  = 'ALL_HIGHLIGHTS_FAIL';

export const DELETE_HIGHLIGHT_REQUEST = 'DELETE_HIGHLIGHT_REQUEST';
export const DELETE_HIGHLIGHT_SUCCESS = 'DELETE_HIGHLIGHT_SUCCESS';
export const DELETE_HIGHLIGHT_FAIL = 'DELETE_HIGHLIGHT_FAIL';
export const DELETE_HIGHLIGHT_RESET = 'DELETE_HIGHLIGHT_RESET';

export const HIGHLIGHT_DETAILS_REQUEST = 'HIGHLIGHT_DETAILS_REQUEST';
export const HIGHLIGHT_DETAILS_SUCCESS = 'HIGHLIGHT_DETAILS_SUCCESS';
export const HIGHLIGHT_DETAILS_FAIL = 'HIGHLIGHT_DETAILS_FAIL';


export const UPDATE_HIGHLIGHT_REQUEST = 'UPDATE_HIGHLIGHT_REQUEST';
export const UPDATE_HIGHLIGHT_SUCCESS = 'UPDATE_HIGHLIGHT_SUCCESS';
export const UPDATE_HIGHLIGHT_FAIL = 'UPDATE_HIGHLIGHT_FAIL';
export const UPDATE_HIGHLIGHT_RESET = 'UPDATE_HIGHLIGHT_RESET';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';